import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/articles-layout.js";
import Quote from "../../components/text-decorations/quote";
import Series from "../../components/series-table";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tweet = makeShortcode("Tweet");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Series mdxType="Series">
    <li><a href="https://landingintech.com/articles/you-dont-need-a-cs-degree/">You don't need a CS degree to be a successful developer</a></li>
    <li><a href="https://landingintech.com/articles/dealing-with-impostor-syndrome/" className="green">On dealing with Impostor Syndrome</a></li>
    </Series>
    <p>{`Impostor Syndrome is such a complex topic that is hard to write about. After talking with such great developers in the podcast, it is safe to say that the Impostor Syndrome is something that we all have to deal with.`}</p>
    <p>{`My goal with this article is to answer some common questions when it comes to the feelings of being an impostor. As well, to share with you some great tips I have received from the first 6 episodes.`}</p>
    <p>{`I asked on Twitter for tips on dealing with the Impostor Syndrome. I will be sharing with you some great tips that I have received on this matter. Hope you like the article and that it provides useful.`}</p>
    <h2 {...{
      "id": "everybody-else-seems-to-be-doing-better-than-me"
    }}>{`Everybody else seems to be doing better than me`}</h2>
    <p>{`Comparing yourself with others is a common thing. It's very frustrating to struggle, while others seem to have everything figured out. `}</p>
    <p>{`Watching what others are doing may be great. It can give you a direction of what you can achieve. But if you are putting yourself down, wondering why this person created x and you haven't, or why you haven't received this or that opportunity. Then it's destructive. `}</p>
    <p>{`With the rise of social media, it's common to have this view that everybody seems to have everything figured out. But the reality is - this isn't true.`}</p>
    <Quote mdxType="Quote">
    <p>"Social media pressure is also very real. The tendency to idealize our online lives is well documented. We often forget that we are likely only looking at someone else’s highlight reel."</p>
    <a href="https://www.smashingmagazine.com/2020/07/thoughts-feelings-creative-wellness/">Jhey - It’s Good To Talk: Thoughts And Feelings On Creative Wellness</a>
    </Quote>
    <p>{`As Jhey wrote in this article, what we are getting is this `}<em parentName="p">{`highlight reel`}</em>{`. Social media doesn't show the real path that someone took to get where they are now. It doesn't show you the struggles or failures.`}</p>
    <h2 {...{
      "id": "perfection-is-an-impossible-measurement"
    }}>{`Perfection is an impossible measurement`}</h2>
    <p>{`When we follow a tutorial or a course we get a finished and polished product. We don't see how many times the author has struggled. How many times something didn't work or how many times the author got stuck.`}</p>
    <p>{`Let's be honest, perfection is something that we will never achieve. But when we consume ready-made products, we feel that this is something that we should seek.
Polished products take time to be created.`}</p>
    <p>{`Being a learner advocate at egghead allows me to talk with different instructors. They all say the same thing. They get things wrong, they make mistakes and they have to re-record lessons.`}</p>
    <p>{`When you consume the polished product, it's hard to imagine that the instructor struggled to explain a concept or made a mistake. But the reality is that they did!`}</p>
    <p><strong parentName="p">{`What can help break out of the perfect view on this?`}</strong></p>
    <p>{`Watching live streams will help you see how someone codes in reality. You see how some streamers get stuck. How they have to google things and even better, you see how a streamer thinks about a problem and how to get unstuck.`}</p>
    <p>{`This was something that helped me with my Impostor Syndrome. When you are a self-taught developer, it's easy to fall into the trap of thinking that everyone else knows everything. That no one struggles or makes mistakes.`}</p>
    <h2 {...{
      "id": "it-might-not-go-away"
    }}>{`It might not go away`}</h2>
    <p>{`This is not something you want to see when reading an article on Impostor Syndrome. The reality is that with how fast tech moves, you will always feel that you need to catch up.`}</p>
    <p>{`You will always feel that you don't know enough. This is actually great because it's something that motivates you to keep learning and improving your skills.`}</p>
    <Tweet tweetLink="_JacobTomlinson/status/1326439159771303936" align="center" mdxType="Tweet" />
    <p>{`Jacob really makes a great point with his reply. The impostor syndrome is a cycle. We learn, we progress, we have that feeling of being an impostor and you go back to the beginning.`}</p>
    <p>{`Now one thing that could make you feel an impostor, is not having a  computer science degree. I've written about `}<a parentName="p" {...{
        "href": "https://landingintech.com/articles/you-dont-need-a-cs-degree/"
      }}>{`not needing to have a computer science degree to be a successful developer`}</a>{`. I feel that the article is a great segway of this one. Hopefully, that article can quiet down some of your doubts!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      